

import "../../assets/icon-16.png";
import "../../assets/icon-32.png";
import "../../assets/icon-80.png";

Office.initialize = function (reason) {
  email = null;
  Office.onReady(function (info) {
    email = Office.context.mailbox.item;
    if (email) {
      ctcViplIsMailSelected = true;
      ctcViplSelectedMail = email;
    }
    ctcViplAddDashboard();
  });
};